@import '~leaflet/dist/leaflet.css';
@import 'rc-slider/assets/index.css';

.leaflet-container {
  width: 100%;
  height: 100vh;
}

.radar-controls {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 1000;
  padding: 0.7rem;
  width: calc(100vw - 2rem);
  background-color: white;
  border-radius: 10px;
  text-align: center;

  @media only screen and (min-width : 480px) {
    width: 20rem;
  }
}

.radar-settings {
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 1000;
  padding: 0.7rem;
  background-color: white;
  border-radius: 10px;
  text-align: center;
}

.slider {
  padding: 0.5rem;
  padding-right: 1rem;
}

.icon-button {
  font-size: 1.2rem;
  line-height: 1;
  cursor: pointer;
}

.rc-slider-handle {
  height: 22px;
  width: 22px;
  margin-top: -9px;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}